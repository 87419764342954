import axios from "axios";
import React, { useState, useEffect } from "react";
import like from "../../assets/images/like.svg";
import dislike from "../../assets/images/dislike.svg";
import Popup from "../feedback/Popup";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStatus } from "../../statusContext";
import { Oval } from "react-loader-spinner";
import FileViewer from "react-file-viewer";
import { Button, Table } from "semantic-ui-react";
import "../../css/index.css";
import KeycloakUserService from "../login/KeycloakUserService";
import "../../css/lexx.css";

function SubmitResult() {
    const location = useLocation();
    const file = sessionStorage.getItem("uploadedFile");
    let type =
        sessionStorage.getItem("type") === "application/pdf" ? "pdf" : "docx";
    const onError = (e) => {
        console.log(e, "error in file-viewer");
    };

    const navigate = useNavigate();
    const navigateToForm = () => {
        if (location.state && location.state.applicant_id) {
            navigate("/formfiledapplicent", {
                state: { applicant_id: location.state.applicant_id },
            });
        } else {
            alert("Form already submitted.");
        }
    };

    const [componentKey, setComponentKey] = useState(0); // Add a key for the component

    const handlenext = () => {
        sessionStorage.clear();
        resetPathway();
        setComponentKey(prevKey => prevKey + 1); // Increment the key to force remount
        navigate("/");
    };

    const [showpopup, setshowpopup] = useState("hide");
    const [rating, setRating] = useState({
        profession: null,
        thumbsDown: false,
    });
    const [mappedResult, setMappedResult] = useState(null);

    const { status, result, docID, pathway, resetPathway } = useStatus();

    useEffect(() => {
        if (result) {
            setMappedResult(result);
        } else {
            setMappedResult(null);
        }
    }, [result]);

    const sendFeedback = async (profession, thumbsUp) => {
        try {
            const token = await KeycloakUserService.getToken();
            const response = await axios.post(
                `https://api.feedback.garuda-dev.lexxtechnologies.com/feedback/${location.state.applicant_id}`,
                { feedback_list: [{ occupation_id: profession, thumbsupdown: thumbsUp }] },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                console.log("Feedback successfully submitted:", response.data);
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error(
                "Error sending feedback:",
                error.response?.data || error.message
            );
        }
    };
    const convertAndSortJobs = (jobs) => {
        if (!jobs || !Array.isArray(jobs)) {
            return [];
        }

        return jobs.map(job => {
            const [startYear, startMonth] = job.period.start.split('-').map(Number);
            const startDate = new Date(startYear, startMonth - 1);

            let endDate, formattedEndDate;
            if (job.period.end === "Present") {
                endDate = "Now";
                formattedEndDate = "Now";
            } else {
                const [endYear, endMonth] = job.period.end.split('-').map(Number);
                endDate = new Date(endYear, endMonth - 1);
                formattedEndDate = endDate.toLocaleDateString("default", {
                    year: "numeric",
                    month: "long",
                });
            }

            const formattedStartDate = startDate.toLocaleDateString("default", {
                year: "numeric",
                month: "long",
            });

            return {
                title: job.title,
                startDate: startDate,
                formattedStartDate: formattedStartDate,
                endDate: endDate,
                formattedEndDate: formattedEndDate,
                country: job.country,
            };
        }).sort((a, b) => a.startDate - b.startDate);
    };

    const convertAndSortEdu = (edu) => {
        if (!edu || !Array.isArray(edu)) {
            return [];
        }
        return edu.map((ed) => ({
            title: ed.title,
            startDate: ed.period.start,
            endDate: ed.period.end,
            country: ed.country,
        }));
    };

    const [showAnzscoPopup, setShowAnzscoPopup] = useState(false);
    const [selectedAnzscoCode, setSelectedAnzscoCode] = useState(null);

    const handleAnzscoClick = (anzscoCode) => {
        setSelectedAnzscoCode(anzscoCode);
        setShowAnzscoPopup(true);
    };

    return (
        <div key={componentKey}> {/* Apply the key here */}
            <div className={`${showpopup === "hide" ? "pophiden" : "popposition"}`}>
                <Popup
                    setshowpopup={setshowpopup}
                    applicent_id={docID}
                    rating={rating}
                />
            </div>
            <div className="result-content">
                <div className="table-result">
                    <h2 className="status">{status}</h2>
                    <h2 className="pathway">Recommended Pathway : {pathway}</h2>
                    {result ? (
                        <>
                            {mappedResult?.info && (
                                <>
                                    <h2 className="professions ">Education</h2>
                                    <Table
                                        celled
                                        unstackable
                                        style={{ border: "none" }}
                                        className="table-wrapper"
                                    >
                                        <tr
                                            style={{
                                                position: "sticky",
                                                top: "0",
                                                zIndex: "1",
                                                backgroundColor: "#0971CE",
                                                color: "white",
                                            }}
                                        >
                                            <th>Title</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Country</th>
                                        </tr>
                                        <tbody>
                                            {convertAndSortEdu(mappedResult?.info?.edu_info || []).map((edu, index) => (
                                                <tr key={index}>
                                                    <td>{edu.title}</td>
                                                    <td>{edu.startDate}</td>
                                                    <td>{edu.endDate}</td>
                                                    <td>{edu.country}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <h2 className="professions ">Employment History</h2>
                                    <Table
                                        celled
                                        unstackable
                                        style={{ border: "none" }}
                                        className="table-wrapper"
                                    >
                                        <tr
                                            style={{
                                                position: "sticky",
                                                top: "0",
                                                zIndex: "1",
                                                backgroundColor: "#0971CE",
                                                color: "white",
                                            }}
                                        >
                                            <th>Title</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Country</th>
                                        </tr>
                                        <tbody>
                                            {convertAndSortJobs(mappedResult?.info?.emp_info || []).map((job, index) => (
                                                <tr key={index}>
                                                    <td>{job.title}</td>
                                                    <td>{job.formattedStartDate}</td>
                                                    <td>{job.formattedEndDate === 'Now' ? 'Now' : job.formattedEndDate}</td>
                                                    <td>{job.country}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                            <h2 className="professions ">Top 5 Professions</h2>
                            <Table
                                celled
                                unstackable
                                style={{ border: "none" }}
                                className="table-wrapper"
                            >
                                <thead style={{ backgroundColor: "#0971ce", color: "white" }}>
                                    <tr>
                                        <th>ANZSCO CODE</th>
                                        <th>PROFESSIONS</th>
                                        <th>SCORE</th>
                                        <th colSpan="2">RATING</th> {/* Occupy two columns for like/dislike */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {mappedResult?.occupation?.map((profession, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button
                                                    onClick={() => handleAnzscoClick(profession.anzsco_code)}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: 0,
                                                        font: 'inherit',
                                                        cursor: 'pointer',
                                                        color: '#0971CE', // Optional: Make the code look like a link
                                                        textDecoration: 'underline', // Optional: Add underline
                                                    }}
                                                >
                                                    {profession.anzsco_code}
                                                </button>
                                            </td>
                                            <td>{profession.occupation_name}</td>
                                            <td>{profession.score}</td>
                                            <td>
                                                <img
                                                    src={like}
                                                    alt="like"
                                                    className={`thumbs-up ${
                                                        rating.profession === profession.anzsco_code && !rating.thumbsDown
                                                            ? "thumbs-up-selected"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        sendFeedback(profession.anzsco_code, true);
                                                        setshowpopup("show");
                                                        setRating({
                                                            profession: profession.anzsco_code,
                                                            thumbsDown: false,
                                                        });
                                                    }}
                                                ></img>
                                            </td>
                                            <td>
                                                <img
                                                    src={dislike}
                                                    alt="dislike"
                                                    className={`thumbs-down ${
                                                        rating.profession === profession.anzsco_code && rating.thumbsDown
                                                            ? "thumbs-down-selected"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        sendFeedback(profession.anzsco_code, false);
                                                        setshowpopup("show");
                                                        setRating({
                                                            profession: profession.anzsco_code,
                                                            thumbsDown: true,
                                                        });
                                                    }}
                                                ></img>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <div style={{ marginLeft: "200px" }}>
                            <Oval
                                height={50}
                                width={50}
                                color="#0971ce"
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="gray"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}
                 </div>
                <div className="table-result-viewer">
                    <FileViewer fileType={type} filePath={file} onError={onError} />
                </div>
            </div>

            {showAnzscoPopup && (
                <AnzscoPopup
                    anzscoCode={selectedAnzscoCode}
                    onClose={() => setShowAnzscoPopup(false)}
                    applicantId={location.state.applicant_id} // Pass the applicantId here
                />
            )}

            <div className="button-previous">
                <Button
                    className="button-1"
                    onClick={navigateToForm}
                    disabled={status !== "Not a Resume"}
                >
                    <span>Profile Info</span>
                </Button>
                <Link to={"/uploadfile"}>
                    <Button className="button-1" disabled={result}>
                        Back
                    </Button>
                </Link>
                <Button className="button-1" onClick={handlenext}>
                    <span>Next</span>
                </Button>
            </div>
        </div>
    );
}


const AnzscoPopup = ({ anzscoCode, onClose, applicantId }) => {
    const [visaData, setVisaData] = useState(null);
    const [stateTerritoryData, setStateTerritoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [occupation, setOccupation] = useState("");
    const [showVisaTable, setShowVisaTable] = useState(true);

    useEffect(() => {
        const fetchAnzscoData = async () => {
            try {
                const token = await KeycloakUserService.getToken();
                const response = await axios.get(
                    `https://api.regional.garuda-dev.lexxtechnologies.com/process/${anzscoCode}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setOccupation(response.data.occupation);
                setVisaData(response.data.data);

            } catch (error) {
                console.error("API Error:", error);
                setError(error.message);
                setVisaData(null);
            } finally {
                setLoading(false);
            }
        };

        const fetchStateTerritoryData = async () => {
            try {
                const token = await KeycloakUserService.getToken();
                const response = await axios.get(
                    `https://api.regional.garuda-dev.lexxtechnologies.com/regional_pathways/${applicantId}/${anzscoCode}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setStateTerritoryData(response.data);
            } catch (error) {
                console.error("State/Territory API Error:", error);
                setError(error.message);
                setStateTerritoryData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchAnzscoData();
        fetchStateTerritoryData();
    }, [anzscoCode, applicantId]);

    const handleStateTerritoryClick = () => {
        setShowVisaTable(false);
    };

    const handleVisaTableClick = () => {
        setShowVisaTable(true);
    };
    const renderStateTerritoryEligibility = (eligibility) => {
        return eligibility 
            ? <span style={{ color: 'green' }}>✅ </span> 
            : <span style={{ color: 'red' }}>❌</span>;
    };
    
    const renderVisaData = (value) => {
        return value 
            ? <span style={{ color: 'green' }}>✅ </span> 
            : <span style={{ color: 'red' }}>❌</span>;
    };
    


    return (
        <div className="anzsco-popup">
            <div className="anzsco-popup-content">
                <h2>{occupation}</h2>

                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="text-red-500">Error: {error}</p>
                ) : (
                    <>
                        {showVisaTable ? (
                            <>
                                <button onClick={handleStateTerritoryClick}>State/Territory</button>
                                <table className="anzsco-table">
                                    <thead>
                                        <tr>
                                            <th>Visa Type</th>
                                            <th>Medium and Long-Term Strategic Skills List (MLTSSL)</th>
                                            <th>Short-Term Skilled Occupation List (STSOL) </th>
                                            <th>Regional Occupation List (ROL)</th>
                                            <th>Core Skills Occupation List(CSOL )</th>
                                            {/* <th>Regional</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visaData && Object.entries(visaData).map(([visaType, data]) => (
                                            <tr key={visaType}>
                                                <td>{visaType}</td>
                                                <td>{renderVisaData(data.MLTSSL)}</td>
                                                <td>{renderVisaData(data.STSOL)}</td>
                                                <td>{renderVisaData(data.ROL)}</td>
                                                <td>{renderVisaData(data.CSOL)}</td>
                                                {/* <td>{data.Regional && data.Regional.length > 0 ? data.Regional.join(", ") : ""}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <>
                                <button onClick={handleVisaTableClick}>Visa Information</button>
                                <div className="state-territory-table-container">
                                    <table className="state-territory-table">
                                        <thead>
                                            <tr>
                                                <th>State/Territory</th>
                                                <th>Visa Subclass</th>
                                                <th>Pathway</th>
                                                <th>Eligibility</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stateTerritoryData && Object.entries(stateTerritoryData).map(([state, visaTypes]) => (
                                                Object.entries(visaTypes).map(([visaSubclass, pathways]) => (
                                                    Object.entries(pathways).map(([pathway, details]) => (
                                                        <tr key={`${state}-${visaSubclass}-${pathway}`}>
                                                            <td>{state}</td>
                                                            <td>{visaSubclass}</td>
                                                            <td>{pathway}</td>
                                                            <td>{renderStateTerritoryEligibility(details.eligibility)}</td>
                                                            <td>{details.comments}</td>
                                                        </tr>
                                                    ))
                                                ))
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </>
                )}

                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SubmitResult;