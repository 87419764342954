/*******************************************************************************
 * Dashboard: This components used for main dashboard
 * Contributors: Abhishek Kuamr Yadav
 *******************************************************************************/
import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import LexxDashboardCard from "./LexxDashbordCard";
import DocumentList from "./documents";
import KeycloakUserService from "../login/KeycloakUserService"; 

import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";
import "../../css/index.css";
import "../../css/lexx.css";

/* This is the component to display the Dashboard (main page after login) */
const LexxDashboard = (props) => {
  /* This component calls 2 components 
        1. Dashboard Card - for buttons
        2. Document list  - for file store
    */
  const [isLoading, setIsLoading] = useState(false);
  const data = JSON.parse(localStorage.getItem("data"));
  const applicant_id = data ? data.applicant_id : null;
  const navigate = useNavigate();

  const [disableChecklistbtn, setDisableChecklistbtn] = useState(
    localStorage.getItem("data") ? true : false
  );
  const [disablegenEmpbtn, setDisablegenEmpbtn] = useState(
    localStorage.getItem("data") ? true : false
  );
   const [disablegenCollectbtn, setDisableCollectEmpbtn] = useState(
    localStorage.getItem("data") ? true : false
  );
  const handleGenerateEmploymentCB = async (value) => {
    setIsLoading(true);
  
    try {
      // Assuming you have a function to get the Keycloak token
      const token = await KeycloakUserService.getToken(); // Get the Keycloak token
  
      const response = await fetch(`https://api.anzsco.garuda-dev.lexxtechnologies.com/empdetails/${applicant_id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${ token}`, // Include the Keycloak token in the Authorization header
        },
      });

      if (!response.ok) { // Check if the response status is not 2xx
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    navigate("/generate", {
        state: data.data,
    });
} catch (error) {
    console.error("Error fetching employment details:", error);
} finally {
    setIsLoading(false);
}
};
  
const handleCollateAndVerifyCB = async (value) => {
  setIsLoading(true);

  try {
    const token = await KeycloakUserService.getToken(); // Get the Keycloak token
    const response = await fetch(`https://api.anzsco.garuda-dev.lexxtechnologies.com/validate/${applicant_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add Keycloak token here
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Save the data to session storage
    sessionStorage.setItem('validationData', JSON.stringify(data.data));

    navigate("/validationpage");
  } catch (error) {
    console.error("Error during validation:", error);
  } finally {
    setIsLoading(false);
  }
};

  
  return (
    <>
      {!isLoading ? (
        <div className="content-container home-container">
          <Container className="dashboard-container">
            <h2>Dashboard</h2>
            <hr style={{ opacity: "50%" }} />
            <LexxDashboardCard disableChecklistbtn={disableChecklistbtn}
              disablegenEmpbtn={disablegenEmpbtn}
              disablegenCollectbtn={disablegenCollectbtn}
              GenerateEmploymentCB={handleGenerateEmploymentCB}
              GenerateCollectCB={handleCollateAndVerifyCB}
            />
            <DocumentList
              setDisableChecklistbtn={setDisableChecklistbtn}
              setDisablegenEmpbtn={setDisablegenEmpbtn}
              setDisableCollectEmpbtn={setDisableCollectEmpbtn}
            />
          </Container>
        </div>
      ) : <div className="loading"><div>
        <Oval
          height={50}
          width={50}
          color="#0971ce"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="gray"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div></div>
      }
    </>
  );
};

export default LexxDashboard;
