import React, { useState } from "react";
import { Dropdown, Button } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStatus } from "../../statusContext";
import KeycloakUserService from "../login/KeycloakUserService";
import "semantic-ui-css/semantic.min.css";
import "../../css/index.css";
import "../../css/lexx.css";

function FileUpload() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setStatus, setResult, setDocName, setDashStatus, setDocID, setPathway } = useStatus();
  const [xmlFile, setXmlFile] = useState(null);
  const [filename, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      if (!["pdf", "doc", "docx"].includes(extension.toLowerCase())) {
        alert("Please choose a PDF, DOC, or DOCX file.");
        event.target.value = null;
        setFileName("");
        setXmlFile(null);
        return;
      }
      const dname = file.name.split(".").slice(0, -1).join(".");
      setFileName(dname);
      setXmlFile(file);
      setDocName(dname);
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileData = e.target.result;
        sessionStorage.setItem("uploadedFile", fileData);
        sessionStorage.setItem("filename", file.name);
        sessionStorage.setItem("type", file.type);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAbort = async () => {
    try {
      const token = await KeycloakUserService.getToken();
      const response = await fetch(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/abort/${location.state.applicant_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        console.log("Abort successful");
        navigate("/");
      } else {
        console.error("Failed to abort:", response.statusText);
      }
    } catch (error) {
      console.error("There was an error aborting the process", error);
    }
  };

  const handleSubmit = async () => {
    setStatus("");
    setResult("");
    setIsLoading(true);
  
    const { state } = location;
    if (!xmlFile) {
      alert("Please choose a file to upload!");
      setIsLoading(false);
      return;
    }
    if (!selectedAssessment) {
      alert("Please select an assessing body.");
      setIsLoading(false);
      return;
    }
    if (!selectedModal) {
      alert("Please select a modal.");
      setIsLoading(false);
      return;
    }
    if (!state || !state.applicant_id) {
      console.error("Applicant ID is missing in the location state.");
      setIsLoading(false);
      alert("The Processing has been interrupted. Please go to the dashboard and try again.");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", xmlFile);
    formData.append("assessing_body", selectedAssessment);
    formData.append("model", selectedModal);
  
    try {
      const token = await KeycloakUserService.getToken();
      const response = await fetch(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/upload/${state.applicant_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
  
      if (response.ok) {
        const jsonData = await response.json();
        if (!jsonData.applicant_id) {
          throw new Error("Document ID is missing.");
        }
  
        setDocID(jsonData.applicant_id);
        await pollServerForStatus(jsonData.applicant_id, token);
      } else {
        console.error("Failed to upload:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error uploading the file", error);
      setIsLoading(false);
      alert("The Processing has been interrupted. Please go to the dashboard and try again.");
    } finally {
      if (xmlFile) {
        setIsLoading(false);
        navigate("/submitresult", {
          state: { applicant_id: state.applicant_id },
        });
      }
    }
  };
  
  const pollServerForStatus = async (applicantId, token) => {
    try {
      const interval = setInterval(async () => {
        const statusResponse = await fetch(`https://api.anzsco.garuda-dev.lexxtechnologies.com/process/${applicantId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (statusResponse.ok) {
          const statusData = await statusResponse.json();
          console.log('Received:', statusData);
  
          setStatus(statusData.status);
          setDashStatus(statusData.status);
            setPathway(statusData.pathway);
  
          if (statusData.status === "Assessed" || statusData.status === "Error" || statusData.status === "Not a Resume") {
            clearInterval(interval);
            setResult(statusData);
            setDashStatus("");
            setIsLoading(false);
          }
        } else {
          console.error("Failed to fetch status:", statusResponse.statusText);
          setIsLoading(false);
          clearInterval(interval);
        }
      }, 15000);
    } catch (error) {
      console.error("Error occurred while polling the server", error);
      setStatus('Error: Could not fetch the status from the server.');
      setIsLoading(false);
    }
  };
  
  const options = [
    { key: 1, text: "Australian Computer Society", value: "ACS" },
    { key: 2, text: "Engineers Australia", value: "Engineers Australia" },
    { key: 3, text: "TRA", value: "TRA" },
    { key: 4, text: "VETASSESS Trade", value: "VETASSESS Trade" },
    { key: 4, text: "VETASSESS Professional", value: "VETASSESS Professional" },
  ];

    const modalOptions = [
        { key: 1, text: "AI21-Jamba-1-5-Mini", value: "AI21-Jamba-1-5-Mini" },
        { key: 2, text: "Meta-Llama-3-1-8B-Instruct", value: "Meta-Llama-3-1-8B-Instruct" },
        { key: 3, text: "Meta-Llama-3-1-70B-Instruct", value: "Meta-Llama-3-1-70B-Instruct" },
        { key: 4, text: "Llama-3-3-70B-Instruct", value: "Llama-3-3-70B-Instruct" },
        { key: 5, text: "gemini-1.5-flash-8b", value: "gemini-1.5-flash-8b" },
        { key: 6, text: "gemini-1.5-pro", value: "gemini-1.5-pro" },
        { key: 7, text: "gemini-1.5-flash", value: "gemini-1.5-flash" },
        { key: 8, text: "gemini-2.0-flash", value: "gemini-2.0-flash" },
        { key: 9, text: "gemini-2.0-flash-lite", value: "gemini-2.0-flash-lite" },
        { key: 10, text: "gemini-2.0-pro-exp", value: "gemini-2.0-pro-exp-02-05" },
        { key: 11, text: "gpt-4o-model", value: "gpt-4o-model" },
        { key: 12, text: "gpt-4o-mini", value: "gpt-4o-mini" },
    ];


  const handleAssessmentChange = (e, { value }) => {
    setSelectedAssessment(value);
  };

    const handleModalChange = (e, { value }) => {
        setSelectedModal(value);
    };


  return (
    <>
      <div className="upload-file0pdf">
        <p> Upload a resume here</p>
        <label htmlFor="formFile"></label>
        <input
          type="file"
          id="formFile"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
        />
      </div>


      <div className="access-for-resume">
        <p>Assess for</p>
        <Dropdown
          placeholder="Select Assessing Body"
          fluid
          selection
          options={options}
          onChange={handleAssessmentChange}
          value={selectedAssessment}
        />
      </div>
        <div className="access-for-resume">
            <p>Select Model</p>
            <Dropdown
                placeholder="Select Modal"
                fluid
                selection
                options={modalOptions}
                onChange={handleModalChange}
                value={selectedModal}
            />
        </div>
      <div className="button-upload">
        <Button className="button-1" onClick={handleAbort}>
          Abort
        </Button>
        <Button
          className="button-1"
          onClick={handleSubmit}
          disabled={!xmlFile || isLoading}
        >
          Assess
        </Button>
      </div>
    </>
  );
}

export default FileUpload;