import React, { useState } from "react";
const Popup = (props) => {
  const [feedback, setFeedback] = useState({ feedback: "" });
  const onchange = (e) => {
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };
  const handlesubmitfeedback = () => {
    if (feedback.feedback.trim() === "") {
      alert("Please fill the feedback.");
      return;
    }
    postFeedback(props.applicent_id, {
      feedbacks: [
        {
          occupation: props.rating.profession,
          // thumbsupdown: true,
          comment: feedback.feedback,
        },
      ],
    });
    setFeedback({ feedback: "" });
    props.setshowpopup("hide");
  };
  const handlefalse = () => {
    props.setshowpopup("hide");
  };
  const postFeedback = async (applicantId, feedbackData) => {
    try {
      const url = `https://api.feedback.garuda-dev.lexxtechnologies.com/feedback/${applicantId}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="popupscreen">
      <div className="popscreennew">
        <div>
          <h2 className="popheader">Record Notes for Your Selection</h2>
        </div>
        <label htmlFor="feedback" className="textpopup">
          Enter Your Feedback on the resume and log the reason for your rating.
        </label>
        <textarea
          onChange={(e) => onchange(e)}
          name="feedback"
          value={feedback.feedback}
          className="text-area"
          rows={10}
        ></textarea>
        <div className="button-container">
          <button onClick={() => handlesubmitfeedback()} className="submit-popup">
            Submit
          </button>
          <button className="back-button" onClick={handlefalse}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default Popup;
