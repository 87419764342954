import React, { useState, useEffect, useRef } from "react";
import { Table, Modal } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import KeycloakUserService from "../login/KeycloakUserService"; // Import Keycloak service
import "../Generate/gen.css";

const ValidationPage = () => {
  const [modalState, setModalState] = useState({ open: false, type: null });
  const applicantData = JSON.parse(localStorage.getItem("data"));
  const applicant_id = applicantData?.applicant_id;
  const skill = applicantData?.applicant_name;
  const navigate = useNavigate();
  const validationData = JSON.parse(sessionStorage.getItem("validationData"));
  const tableRef = useRef(); // Reference for printable table

  useEffect(() => {
    if (modalState.open && modalState.type === "success") {
      handleDownload();
    }
  }, [modalState]);

  const handleNextButtonClick = async () => {
    try {
      const token = await KeycloakUserService.getToken(); // Get the Keycloak token
      const response = await fetch(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/status/${applicant_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add Keycloak token here
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("abhishek", data.data)
      processValidationStatus(data.data);
    } catch (error) {
      console.error("Error fetching validation status:", error);
    }
  };
  const processValidationStatus = (data) => {
    if (data.status === "Validation Successful") {
      setModalState({ open: true, type: "success" });
    } else {
      setModalState({ open: true, type: "error" });
    }
  };

  const handleDownload = async () => {
    try {
      const token = await KeycloakUserService.getToken(); // Get the Keycloak token
      const response = await fetch(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/download/${applicant_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Add Keycloak token in Authorization header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "validation_report.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      navigate("/");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleEmailSent = async () => {
    try {
      const token = await KeycloakUserService.getToken(); // Get the Keycloak token
      const response = await fetch(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/sendagain/${applicant_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add Keycloak token in Authorization header
          },
          body: JSON.stringify({}),
        }
      );
      if (response.ok) {
        navigate("/emailsent");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML; // Get the table's HTML content
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Validation Report</title>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const renderModal = () => {
    const isSuccess = modalState.type === "success";
    const modalTitle = isSuccess
      ? "All Documents validated."
      : "Validation Not Successful";
    const modalMessage = isSuccess
      ? "Please Download the Zip File."
      : "Please check your documents and try again.";

    return (
      <Modal
        open={modalState.open}
        onClose={() => setModalState({ open: false, type: null })}
        size="mini"
      >
        <Modal.Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={isSuccess ? faCheckCircle : faExclamationCircle}
            style={{ color: isSuccess ? "#0971ce" : "red", height: "50px" }}
          />
        </Modal.Content>
        <Modal.Content>
          <h1 style={{ margin: 0 }}>{modalTitle}</h1>
          <p style={{ margin: "20px 0", textAlign: "center" }}>
            {modalMessage}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <button
              className="FormSubmitbtenc"
              onClick={() => setModalState({ open: false, type: null })}
            >
              Close
            </button>
            {isSuccess ? (
              <button className="FormSubmitbtenn" onClick={handleDownload}>
                Download
              </button>
            ) : (
              <button className="FormSubmitbtenn" onClick={handleEmailSent}>
                Resend Email
              </button>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  const renderTableRows = () => {
    return Object.entries(validationData).map(([fileName, fileData]) => (
      <Table.Row key={fileName}>
        <Table.Cell>{fileName}</Table.Cell>
        <Table.Cell>{fileData.Type}</Table.Cell>
        <Table.Cell>{fileData["Detected Type"]}</Table.Cell>
        <Table.Cell>{fileData["Name in Document"]}</Table.Cell>
        <Table.Cell>{fileData["Document Match"] ? "Yes" : "No"}</Table.Cell>
        <Table.Cell>{fileData["Name Match"] ? "Yes" : "No"}</Table.Cell>
        <Table.Cell>{fileData.Clear ? "Yes" : "No"}</Table.Cell>
        <Table.Cell>{fileData.Colorful ? "Yes" : "No"}</Table.Cell>
        <Table.Cell>
          <div style={{ maxWidth: "100%" }}>{fileData.Errors}</div>
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <>
      <div className="gen-field">
        <div className="gen-header">
          Document Validation Report
          <h1>{skill}</h1>
        </div>
        <div className="tableWrap" ref={tableRef}>
          <Table
            celled
            unstackable
            style={{ border: "none" }}
            className="table-wrapper"
          >
            <Table.Header>
              <Table.Row>
                {[
                  "File Name",
                  "Type",
                  "Detected Type",
                  "Name in Document",
                  "Document Match",
                  "Name Match",
                  "Clear",
                  "Colorful",
                  "Errors (If any)",
                ].map((header) => (
                  <Table.HeaderCell key={header} className="table-header">
                    {header}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderTableRows()}</Table.Body>
          </Table>
        </div>
        <div className="button-previous-form">
          <Link to={"/"}>
            <button className="FormSubmitbt" type="button">
              Back
            </button>
          </Link>
          <button
            className="FormSubmitbt"
            type="button"
            onClick={handleNextButtonClick}
          >
            Next
          </button>
          <button className="FormSubmitbt" type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
        {renderModal()}
      </div>
    </>
  );
};

export default ValidationPage;
