import React, { useState } from "react";
import Skills from "./Skills";
import Rpl from "./Rpl";
import Pass from "./Pas";
import Tg from "./TempGradu";
import { Link, useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import axios from "axios";
import KeycloakUserService from "../login/KeycloakUserService"; // Import Keycloak service

const ApplicationType = (props) => {
  const data = JSON.parse(localStorage.getItem("data"));
  const skill = data ? data.recommended_pathway : null;
  const applicant_id = data ? data.applicant_id : null;
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [isSubmitClicked, setSubmitClicked] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!applicant_id) {
        console.error("Applicant ID not found in localStorage.");
        return;
      }

      if (!skill || !["Skills", "RPL", "PAS", "TG"].includes(skill)) {
        alert("Please select a pathway before submitting.");
        return;
      }
      setLoading(true); // Start loading
      setSubmitClicked(true); // Disable back button

      const radiovalue = {
        recommended_pathway: skill,
      };

      const token = await KeycloakUserService.getToken(); // Get the Keycloak token

      const response = await axios.post(
        `https://api.anzsco.garuda-dev.lexxtechnologies.com/send/${applicant_id}`,
        radiovalue,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Keycloak token in the request headers
            "Content-Type": "application/json",
          },
        }
      );

      // Check if response status is not OK (200)
      if (response.status !== 200) {
        alert("An error occurred while submitting the data.");
        return;
      }

      // Navigate if response status is OK (200)
      navigate("/emailsent");
    } catch (error) {
      console.error("Error posting data:", error);
      alert(
        "Your email could not be sent. Please ensure the recipient's email address is valid."
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="popupscreenList">
      <div className="cheklist">
        <h2 className="popheaderList">CHECKLIST</h2>
      </div>
      <div
        style={{
          borderBottom: "1px solid #0971ce",
          display: "flex",
          flexDirection: "row",
          fontWeight: "bold",
          marginBottom: "20px",
          width: "90vw",
          cursor: "pointer",
          marginLeft: "30px",
        }}
      ></div>
      <>
        <center>
          <SyncLoader
            className={`${!isloading ? "hidden" : "midd"}`}
            color="#0971ce"
          />
        </center>
        <div className="h-context">
          <h2 className="pathway-1">
            Recommended Pathway:{" "}
            {skill
              ? skill
              : "You have not selected any resume. Please select a resume first"}
          </h2>
        </div>
        {!isloading && (
          <>
            {skill === "Skills" && <Skills />}
            {skill === "RPL" && <Rpl />}
            {skill === "PAS" && <Pass />}
            {skill === "TG" && <Tg />}
          </>
        )}
        <div className="button-p">
          <Link to={"/"}>
            <button className={`back-b ${isSubmitClicked ? 'disabled' : ''}`} disabled={isSubmitClicked}>
              Back
            </button>
          </Link>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
            className="next-s"
            disabled={isloading}
          >
            {isloading ? <SyncLoader color="#ffffff" size={8} /> : "Submit"}
          </button>
        </div>
      </>
    </div>
  );
};

export default ApplicationType;
