import React from "react";
import { Button, Card, Image } from "semantic-ui-react";
import Technician from "../../images/technician.png";
import { useNavigate } from "react-router-dom";

const DashboardCard = ({ disableChecklistbtn = true, disablegenEmpbtn = true, GenerateEmploymentCB,GenerateCollectCB ,disablegenCollectbtn=true}) => {
  
  const data = JSON.parse(localStorage.getItem("data"));
  // eslint-disable-next-line no-unused-vars
  const applicant_id = data ? data.applicant_id : null;
  const navigate = useNavigate();

  const handlechecklist = () => {
    const data = localStorage.getItem("data");
    if (data) {
      const parsed = JSON.parse(data);
      if (parsed.status === "Email Sent") {
        if (window.confirm("Email is already sent. Want to continue?")) {
          navigate("/checklist");
        }
      } else {
        navigate("/checklist");
      }
    }
  };
  //   try {
  //     // Set loading state to true
  //     setLoading(true);
  
  //     // Make GET request to the API endpoint
  //     const response = await fetch(`https://api.anzsco.garuda-dev.lexxtechnologies.com/validate/${applicant_id}`);
  
  //     // Check if response is successful
  //     if (response.ok) {
  //       // Parse response data as JSON
  //       const data = await response.json();
  
  //       // Log the response data to the console
  //       console.log('Response:', data);
  //     } else {
  //       // Log error if response is not successful
  //       console.error('Error:', response.statusText);
  //     }
  //   } catch (error) {
  //     // Log any other errors that occur during the request
  //     console.error('Error:', error);
  //   } finally {
  //     // Set loading state to false after request is completed
  //     setLoading(false);
  //   }
  // };
  const handleCollateAndVerify = async () => {
    GenerateCollectCB(true)
  };
  
  const GenerateEmployment = async () => {
    GenerateEmploymentCB(true);
  };

  return (
    <Card className="dashboard-card">
      <div className="dashboard-card-content">
        <div className="dashboard-card-image">
          <Image src={Technician} className="dashboard-card-image-holder" />
        </div>
        <div className="dashboard-card-messages">
          <h3>Hi, LexX here!</h3>
          <p className="label">What would you like to do?</p>
        </div>
        <div className="dashboard-card-buttons">
          <Button
            className="secondaryButton"
            onClick={() => {
              navigate("/formfiled");
            }}
          >
            Assess a Resume
          </Button>
          <Button
            className="primaryButton"
            disabled={disableChecklistbtn}
            onClick={() => {
              handlechecklist();
            }}
          >
            Create a CheckList
          </Button>
        </div>
        {/* New buttons */}
        <div style={{ marginTop: '10px' }} className="dashboard-card-buttons">
          <Button
            className="secondaryButton"
            disabled={disablegenEmpbtn}
            onClick={GenerateEmployment}
          >
            Generate Emp. Reference
          </Button>
          <Button
            className="primaryButton"
            disabled={disablegenCollectbtn}
            onClick={handleCollateAndVerify}
          >
            Collate and Verify
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default DashboardCard;
